import PropTypes from 'prop-types';

import { FlashSaleType } from 'Type/FlashSaleType';
import { ImageStickersType } from 'Type/ImageStickersType';

export const SliderProductType = PropTypes.shape({
    sku: PropTypes.string,
    name: PropTypes.string,
    mc_inline_description: PropTypes.string,
    mc_dimension_width: PropTypes.number,
    mc_dimension_height: PropTypes.number,
    mc_dimension_depth: PropTypes.number,
    mc_flashsale: FlashSaleType,
    small_image: PropTypes.shape({
        url: PropTypes.string,
        path: PropTypes.string
    }),
    price_range: PropTypes.shape({
        minimum_price: PropTypes.shape({
            final_price: PropTypes.shape({
                currency: PropTypes.string,
                value: PropTypes.number
            }),
            regular_price: PropTypes.shape({
                currency: PropTypes.string,
                value: PropTypes.number
            }),
            discount: PropTypes.shape({
                currency: PropTypes.string,
                value: PropTypes.number
            })
        })
    }),
    mc_image_stickers: ImageStickersType
});

export const SliderProductsType = PropTypes.arrayOf(SliderProductType);

export const SliderThemeType = PropTypes.oneOf(['FlashSales', 'Related']);
export const SliderStickersType = PropTypes.oneOf([
    'none',
    'flash_sales_slider',
    'category',
    'second_level',
    'third_level'
]);
