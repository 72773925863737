/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable no-magic-numbers */
/* eslint-disable fp/no-let */
/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import SwiperCore, {
    A11y, Navigation, Pagination, Scrollbar
} from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import ProductSliderCard from 'Component/ProductSliderCard';
import { SliderProductsType, SliderStickersType, SliderThemeType } from 'Type/SliderProductsType';
import { startMobile } from 'Util/mcMobileCheck';

import BlackFridayCounterCarouselCard from '../BlackFridayCounterCarouselCard/BlackFridayCounterCarouselCard.component';

import 'swiper/swiper.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/navigation/navigation.scss';
import './ProductSlider.style';

// eslint-disable-next-line @scandipwa/scandipwa-guidelines/create-config-files
export const MIN_SLIDES_FOR_LOOP = 4;

/** @namespace Pwa/Component/ProductSlider/Component/ProductSliderComponent */
export class ProductSliderComponent extends PureComponent {
    static propTypes = {
        items: SliderProductsType,
        config: PropTypes.shape({
            breakpoints: PropTypes.object,
            onSlideChange: PropTypes.func,
            onInit: PropTypes.func,
            theme: SliderThemeType,
            stickers: SliderStickersType
        }).isRequired,
        list: PropTypes.string,
        isBlackFriday: PropTypes.bool,
        // eslint-disable-next-line react/require-default-props
        price_range: PropTypes.any
    };

    static defaultProps = {
        items: [],
        list: '',
        isBlackFriday: false
    };

    state = {
        showLoader: false
    };

    componentDidMount() {
        setTimeout(() => {
            this.setState({ showLoader: true });
        }, 1000);
    }

    render() {
        console.log('ProductSliderComponent');
        const {
            items, config: {
                breakpoints, onSlideChange, onInit, theme, stickers
            },
            list,
            isBlackFriday,
            price_range
        } = this.props;
        const { showLoader } = this.state;
        const isSlider = (startMobile() || (items.length > MIN_SLIDES_FOR_LOOP));
        let isbreak = breakpoints;
        if (list === 'third level products variants') {
            isbreak = {
                0: {
                    slidesPerView: 1
                },
                1024: {
                    slidesPerView: 3
                }
            };
        }
        const navigationConfig = isSlider ? {
            nextEl: '.ProductSlider-Next',
            prevEl: '.ProductSlider-Prev',
            disabledClass: 'ProductSlider-Off'
        } : {
            nextEl: '.ProductSlider-Next-Disabled',
            prevEl: '.ProductSlider-Prev-Disabled',
            disabledClass: 'ProductSlider-Disabled'
        };

        SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);
        if (showLoader) {
            return (
                <div block="ProductSlider" mods={ { Theme: theme } }>
                    <Swiper
                      spaceBetween={ 24 }
                      breakpoints={ isbreak }
                      navigation={ navigationConfig }
                      watchOverflow
                      loop={ items.length >= MIN_SLIDES_FOR_LOOP }
                      scrollbar={ { draggable: true } }
                      onSlideChange={ onSlideChange }
                      onSwiper={ onInit }

                    >
                        { items && items.map((card, i) => (
                            <SwiperSlide key={ card.sku }>
                                { (isBlackFriday)
                                    && (
                                        <BlackFridayCounterCarouselCard
                                          card={ card }
                                          theme={ theme }
                                          stickers={ stickers }
                                          position={ i + 1 }
                                          list={ list }
                                          price={ price_range }
                                        />
                                    ) }
                                { (!isBlackFriday)
                                    && (
                                        <ProductSliderCard
                                          card={ card }
                                          theme={ theme }
                                          stickers={ stickers }
                                          position={ i + 1 }
                                          list={ list }
                                          price={ price_range }
                                        />
                                    ) }
                            </SwiperSlide>
                        )) }

                        { isSlider && <div block="ProductSlider" elem="Next" /> }
                        { isSlider && <div block="ProductSlider" elem="Prev" /> }
                    </Swiper>
                </div>
            );
        }

        return null;
    }
}

export default ProductSliderComponent;
