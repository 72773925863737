import { createSelector } from 'reselect';

import { SHOP_LINK } from 'Store/StoreLocator/StoreLocator.config';

/** @namespace Pwa/Store/StoreLocator/Selector/ShopListByRegion/Selector/getTest */
// eslint-disable-next-line max-len
export const getTest = (state, props) => state.StoreLocatorReducer.shopsByRegions.filter((item) => item.slug === props.region);

/** @namespace Pwa/Store/StoreLocator/Selector/ShopListByRegion/Selector/converterLink */
export const converterLink = (id) => SHOP_LINK + encodeURIComponent(id.toLowerCase().replace(' ', '-').toLowerCase());

export const getShopListByRegion = createSelector(
    [getTest],
    (shopsByRegion) => {
        if (shopsByRegion.length === 0) {
            return [];
        }

        return shopsByRegion[0].shops.map((item) => ({
            name: item.name,
            link: ''
            // link: converterLink(item.id)
        }));
    }
);
